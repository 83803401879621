html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
}

#root {
  height: 100%;
}
